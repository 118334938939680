import { motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'
import { Text, Flex, FadeIn } from '.'

const transition = { default: { duration: 0.4 } }
const CardContainer = ({ children, ...props }) => (
  <motion.div
    {...props} variants={{
      open: { height: 'auto', opacity: 1, marginTop: 10, transition },
      closed: { height: 0, opacity: 0, marginTop: 0, transition }
    }}
  >
    {children}
  </motion.div>
)

const ChevronContainer = ({ children, ...props }) => (
  <motion.div
    {...props} variants={{
      open: { rotate: 0, transition },
      closed: { rotate: 180, transition }
    }}
  >
    {children}
  </motion.div>
)

export const Card = ({ fadeIn, noBackground, alwaysVisibleComponent, collapsable, title, titleCollapsed, titleStyle, collapsed, children, style, customHeader, onExpand, onClick = () => { } }) => {
  const [state, setState] = useState('closed')
  const isCollapsed = state === 'closed'

  const toggleCollapse = (newState) => {
    const applyNewState = newState || (isCollapsed ? 'open' : 'closed')
    setState(applyNewState)
    applyNewState === 'open' && onExpand && onExpand()
  }

  useEffect(() => { collapsable && setState(collapsed ? 'closed' : 'open') }, [collapsable, collapsed])

  const cardStyle = {
    backgroundColor: !noBackground && 'rgba(255, 255, 255, 0.7)',
    borderRadius: 20,
    padding: 20,
    backdropFilter: !noBackground && 'blur(16px) saturate(180%)',
    WebkitBackdropFilter: !noBackground && 'blur(16px) saturate(180%)',
    border: '1px solid rgba(209, 213, 219, 0.3)',
    boxShadow: '10px 10px 20px 0px  rgba(100, 100, 100, 0.24)',
    ...(collapsable ? { position: 'relative' } : {}),
    ...(collapsable && isCollapsed && !customHeader ? { cursor: 'pointer' } : {}),
    ...style,
    width: undefined
  }

  return (
    <FadeIn enabled={fadeIn} style={{ ...(style ? { width: style.width, height: style.height } : {}) }}>
      <div style={cardStyle} {...(isCollapsed && !customHeader) ? { onClick: () => toggleCollapse('open') } : {}}>
        {!customHeader && !title
          ? null
          : (
            <Flex row jb fw>
              <Flex fw row js>
                {title ? <Text title bold value={titleCollapsed ? (isCollapsed ? titleCollapsed : title?.toUpperCase()) : title?.toUpperCase()} style={{ ...titleStyle, marginRight: 20 }} size={20} /> : null}

                {customHeader}

              </Flex>
              {collapsable && (
                <ChevronContainer initial='closed' animate={state} style={{ cursor: 'pointer' }} onClick={() => (customHeader || !isCollapsed) && toggleCollapse()}>
                  <i className='pi pi-chevron-down' style={{ fontSize: '1.5em' }} />
                </ChevronContainer>
              )}
            </Flex>)}
        {!collapsable
          ? (
            <div onClick={onClick}>
              {children}
              {alwaysVisibleComponent}
            </div>)
          : (
            <>
              <CardContainer initial='closed' style={{ overflow: 'hidden' }} animate={state}>
                {children}
              </CardContainer>
              {alwaysVisibleComponent}
            </>)}
      </div>
    </FadeIn>
  )
}
